@font-face {
  font-family: "Mary-Ann";
  font-style: "italic";
  font-weight: "extrabold";
  src: local("Mary-Ann"),
    url("./assets/fonts/mary-ann/Mary-Ann-ExtraBoldItalic.otf")
      format("opentype");
}

@font-face {
  font-family: "Mary-Ann";
  font-weight: "bold";
  src: local("Mary-Ann"),
    url("./assets/fonts/mary-ann/Mary-Ann-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Mary-Ann";
  font-weight: "bold";
  font-style: "italic";
  src: local("Mary-Ann"),
    url("./assets/fonts/mary-ann/Mary-Ann-BoldItalic.otf") format("opentype");
}

@font-face {
  font-family: "Mary-Ann";
  font-style: "italic";
  font-weight: "extrabold";
  src: local("Mary-Ann"),
    url("./assets/fonts/mary-ann/Mary-Ann-ExtraBoldItalic.otf")
      format("opentype");
}

@font-face {
  font-family: "Mary-Ann";
  font-style: "italic";
  src: local("Mary-Ann"),
    url("./assets/fonts/mary-ann/Mary-Ann-Italic.otf") format("opentype");
}

@font-face {
  font-family: "Mary-Ann";
  font-weight: "light";
  src: local("Mary-Ann"),
    url("./assets/fonts/mary-ann/Mary-Ann-Light.otf") format("opentype");
}

@font-face {
  font-family: "Mary-Ann";
  font-style: "italic";
  font-weight: "light";
  src: local("Mary-Ann"),
    url("./assets/fonts/mary-ann/Mary-Ann-LightItalic.otf") format("opentype");
}

@font-face {
  font-family: "Mary-Ann";
  font-weight: "400";
  src: local("Mary-Ann"),
    url("./assets/fonts/mary-ann/Mary-Ann-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Mary-Ann";
  font-style: "italic";
  font-weight: "400";
  src: local("Mary-Ann"),
    url("./assets/fonts/mary-ann/Mary-Ann-MediumItalic.otf") format("opentype");
}

@font-face {
  font-family: "Mary-Ann";
  src: local("Mary-Ann"),
    url("./assets/fonts/mary-ann/Mary-Ann-Regular.otf") format("opentype");
}
